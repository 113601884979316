import React from "react"
import style from "./card.mod.scss"
import styledCards from "./cardsContainer.mod.scss"
import Icon from "@elements/Icon"
import { GatsbyImage } from "gatsby-plugin-image"
import { getImageFromMedia } from "@helpers/workoutHelper"
import { Link } from "gatsby"
import FavouriteHeartButton from "./FavouriteHeartButton"
import EquipmentList from "../../workout/EquipmentList"
// import CompletedIcon from "../cards/CompletedIcon"

const FavouritesCard = ({
  title,
  image,
  chosenThumbnail,
  subHeading,
  iconOne,
  iconTwo,
  type,
  path,
  onRemoveFavourite,
  onAddFavourite,
  hasImageApiKey,
  showIcons,
  showHeart,
  equipment,
  id,
  favouriteArray = [],
  isFeatured = false
}) => {
  return (
    <div className={style.card}>
      {showHeart && (
        <div className={style.card__heart}>
          <FavouriteHeartButton
            large={true}
            prompt={true}
            favouriteArray={favouriteArray}
            onRemoveFavourite={onRemoveFavourite}
            onAddFavourite={onAddFavourite}
            id={id}
            isFeatured={isFeatured}
          />
        </div>
      )}
      <Link to={path}>
        <div className={styledCards.card__item}>
          <div className={style.card__inner}>
            <div className={style.card__wrapper}>
              {equipment && <EquipmentList items={equipment} modifier="card" />}
              {/* {hasImageApiKey && <GatsbyImage image={thumb.gatsbyImageData} className={style.card__image} />} */}
              {image && (
                <GatsbyImage
                  image={
                    image.gatsbyImageData ||
                    image.chosenThumbnail?.gatsbyImageData
                  }
                  className={style.card__image}
                />
              )}

              {
                // Uncomment to enable tick on favourites cards
                /* {id && (
                <div className={style.card__tick}>
                  <CompletedIcon id={id} />
                </div>
              )} */
              }
            </div>
            <div className={style.card__content}>
              <h3 className={style.card__title}>{title}</h3>
              {subHeading && (
                <h4 className={style.card__subHeading}>{subHeading}</h4>
              )}
              {showIcons && (
                <div className={style.card__icons}>
                  <Icon
                    type={type === "workout" ? "cook" : "prep"}
                    content={`${iconOne.content}`}
                    subText={iconOne.subText}
                  />
                  <Icon
                    type={type === "workout" ? "drop" : "cook"}
                    content={iconTwo.content}
                    subText={iconTwo.subText}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default FavouritesCard

// const CardImage = ({ image }) => {
//   const thumb = getImageFromMedia(image)
//   const config = {
//     image: thumb.gatsbyImageData,
//     className: style.card__image
//   }

//   return <GatsbyImage {...config} />
// }
