import React from "react"
import { graphql } from "gatsby"
import Layout from "@layouts/layout"
import Content from "@elements/Content"
import Seo from "@elements/Seo"
import Block from "@global/layout/Block"
import FavouritesCard from "../components/global/cards/FavouritesCard"
import { format } from "date-fns"
import { useAuth0 } from "@utils/auth"
import CardsWrapper from "../components/global/cards/CardsWrapper"
import Banner from "@global/layout/Banner"

const Vlogs = ({ data, pageContext: { title, parent } }) => {
  const { nodes } = data.vlogs
  const { vlogs } = data.content
  const { latestFromKateBanner } = data.banner
  const { addFavouriteVlogs, favouriteVlogs, removeFavouriteVlogs } = useAuth0()

  return (
    <Layout parent={parent} title={title}>
      <Seo
        title={vlogs.meta.title}
        description={vlogs.meta.desc}
        image={latestFromKateBanner.url}
      />
      <Banner image={latestFromKateBanner} />
      <Block padding="double-top" gutters={true} maxWidth="inner">
        <h1>{data.miscContent.tipsHeading}</h1>
        <Content html={`<p>${data.miscContent.tipsIntroText}</p>`} />
        <br />
        <p>{"Kate x"}</p>
      </Block>
      <Block gutters={true} padding="both" maxWidth="list-inner">
        <CardsWrapper>
          {nodes.map(node => {
            const { path, chosenThumbnail, title, meta, originalId } = node
            return (
              <li key={originalId}>
                <FavouritesCard
                  id={originalId}
                  title={title}
                  subHeading={format(
                    new Date(meta?.publishedAt),
                    "d MMMM yyyy"
                  )}
                  image={chosenThumbnail}
                  path={path}
                  showIcons={false}
                  onAddFavourite={addFavouriteVlogs}
                  onRemoveFavourite={removeFavouriteVlogs}
                  favouriteArray={favouriteVlogs}
                  large={true}
                  showHeart={true}
                  type={"vlogs"}
                />
              </li>
            )
          })}
        </CardsWrapper>
      </Block>
    </Layout>
  )
}

export const query = graphql`
  query vlogsQuery {
    vlogs: allDatoCmsVlogPost(
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      nodes {
        title
        slug
        id
        originalId
        path
        description
        chosenThumbnail {
          gatsbyImageData(imgixParams: { fit: "crop", w: "740", h: "532" })
        }
        meta {
          publishedAt
        }
      }
    }

    banner: datoCmsGlobal {
      latestFromKateBanner {
        gatsbyImageData(
          imgixParams: { w: "1280", h: "640", fit: "crop", auto: "true" }
        )
        url(imgixParams: { w: "1000", fit: "max", fm: "jpg" })
      }
    }

    content: contentYaml(language: { eq: "en-NZ" }) {
      vlogs {
        content
        meta {
          title
          desc
        }
      }
    }
    seo: datoCmsSite {
      ...GlobalSeo
    }
    miscContent: datoCmsMiscContent {
      tipsIntroText
      tipsHeading
    }
  }
`

export default Vlogs
